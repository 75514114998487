<template>
<div id="page" class="security-fund-manage-index">
  <el-form :model="form" class="row-center-start" ref="formRef">
    <el-row>
      <el-form-item label="设备类型">
        <el-select v-model="form.device_type_id" size="small">
          <el-option v-for="item in options.deviceTypeOption" :key="item.t_device_type_id" :label="item.txt_type_name" :value="item.t_device_type_id" />
        </el-select>
      </el-form-item>
      <el-form-item label="代理地区" prop="province_code">
        <el-select v-model="form.province_code" size="small" @change="provinceChange()" placeholder="省">
          <el-option v-for="item in options.provinceOption" :key="item.txt_province_code" :label="item.txt_province_name" :value="item.txt_province_code" />
        </el-select>
        <el-select v-model="form.city_code" size="small" :disabled="form.province_code==null" placeholder="市">
          <el-option v-for="item in options.cityOption" :key="item.txt_city_code" :label="item.txt_city_name" :value="item.txt_city_code" />
        </el-select>
      </el-form-item>
      <el-form-item label="根据合伙人筛选" prop="t_pagent_id">
        <el-cascader v-model="form.t_pagent_id" :options="options.partnerOption" :props="{checkStrictly: false, value: 't_agent_id', label: 'txt_realname', children: '_child'}" />
      </el-form-item>
      <el-form-item label="保证金缴纳状态">
        <el-select v-model="form.paystatus" placeholder="请选择" size="small">
          <el-option v-for="item in options.statusOption" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-row>
  </el-form>
  <el-table :data="table.tableData" v-loading="table.tabelLoading" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" v-if="table.inputShow" />
    <el-table-column prop="txt_clinic_agent_name" label="合伙人" align="center" />
    <el-table-column prop="bankCardNo" label="设备类型" align="center">{{table.currentDevice}}</el-table-column>
    <el-table-column prop="txt_device_iot" label="设备IOT" align="center" />
    <el-table-column prop="dt_agent" label="绑定时间" align="center" />
    <el-table-column prop="dec_agent_amount" label="缴纳保证金" align="center">
      <template #default="scope">{{scope.row.dec_agent_amount||0}}</template>
    </el-table-column>
    <el-table-column v-if="!table.inputShow" prop="dec_returned" label="已返还" align="center">
      <template #default="scope">{{parseFloat(scope.row.dec_returned).toFixed(2)}}</template>
    </el-table-column>
    <el-table-column label="保证金录入" v-if="table.inputShow" align="center" class-name="table-option">
      <template #default="scope">
        <el-input class="table-input" v-model="scope.row.dec_agent_amount" @input="numberInput($event, scope.row)" />
        <div class="table-icon" @click="confirmInput(scope.row, 'single')">
          <el-icon>
            <Check />
          </el-icon>
          确认
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="sfmi-bottom" v-if="form.paystatus == 0">
    <el-input size="small" v-model="table.batchInput" @input="batchFormat" />
    <el-button type="primary" @click="handleBatchInput()">批量录入</el-button>
  </div>
</div>
</template>

<script>
// 总账管理 - 保证金
import { useStore } from 'vuex';
import { Check } from "@element-plus/icons-vue";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { ElMessage, FormInstance } from 'element-plus';
import { getDeviceType, getAgentHasDevice, getSecurityList, getProCityData, insertFund } from "api/apis.js";
export default {
  components: {
    Check,
  },
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      formRef: ref(FormInstance),
      options: {
        deviceTypeOption: [],
        statusOption: [
          { label: "未支付", value: 0 },
          { label: "已支付", value: 1 },
        ],
        provinceOption: [],
        cityOption: [],
        partnerOption: [],
      },
      form: {
        device_type_id: null,
        paystatus: 0,
        t_pagent_id: null,
        province_code: null,
        city_code: null
      },
      table: {
        tabelLoading: false,
        tableData: [],
        currentDevice: null,
        tableChecked: [],
        batchInput: null,
        inputShow: true
      },
    });

    onMounted(() => {
      getDevices();
      getPartners();
    });

    // 省级选择器 切换事件
    const provinceChange = () => {
      state.form.city_code = null;
      getProCityOption(state.form.province_code);
    };

    // 批量录入
    const handleBatchInput = () => {
      if ((state.table.tableChecked || []).length == 0) {
        ElMessage.warning("请至少选择一个业务员");
      } else {
        confirmInput(state.table.batchInput, "batch");
      }
    };

    // 确认提交
    const confirmInput = (param, type) => {
      let agent_device_ids = [],
        amount = null;
      if (type == "single") {
        agent_device_ids = [param.t_agent_device_id];
        amount = param.dec_agent_amount;
      } else {
        amount = param;
        state.table.tableChecked.forEach(item => {
          agent_device_ids.push(item.t_agent_device_id);
        })
      }

      state.table.tabelLoading = true;
      insertFund({
        agent_device_ids: agent_device_ids,
        amount: amount,
        txt_creator: store.getters.userInfo.name || "系统用户",
        dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("保证金设置成功！");
          getList();
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 表格选择事件
    const handleSelectionChange = (value) => {
      state.table.tableChecked = value;
    }

    // 批量录入格式化
    const batchFormat = (e) => {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      e = e.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      state.table.batchInput = e;
    };

    // 数字输入事件，格式化
    const numberInput = (e, item) => {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      e = e.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      item.dec_agent_amount = e;
    };

    // 获取列表数据
    const getList = () => {
      // 判断必填项是否已选
      if (state.form.device_type_id == null || state.form.paystatus == null) {
        ElMessage({
          type: "warning",
          message: "请选择设备类型/保证金缴纳状态",
          duration: 2000
        })
      } else {
        state.table.tabelLoading = true;
        // 修改表格 - 设备类型文字
        handleChange(state.form.device_type_id);
        let keysArr = Object.keys(state.form),
          param = {
            device_type_id: state.form.device_type_id,
            paystatus: state.form.paystatus,
          };
        keysArr.forEach(item => {
          if (state.form[item] != null) {
            param[item] = state.form[item];
          }
        });
        // 判断合伙人是否已选
        if ((state.form.t_pagent_id || []).length != 0) {
          param.salesman_id = state.form.t_pagent_id[state.form.t_pagent_id.length - 1];
        }
        getSecurityList(param).then(response => {
          if (response.code == 200) {
            state.table.tableData = response.data;
          } else {
            ElMessage.error(response.msg);
            state.table.tableData = [];
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          // 设置表格input框是否展示
          if (state.form.paystatus == 0) {
            state.table.inputShow = true;
          } else {
            state.table.inputShow = false;
          }
          state.table.tabelLoading = false;
        })
      }
    }

    // 设备类型选择器 - 选择事件
    const handleChange = (e) => {
      let index = state.options.deviceTypeOption.findIndex(t => t.t_device_type_id == e);
      state.table.currentDevice = state.options.deviceTypeOption[index].txt_type_name;
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.options.deviceTypeOption = response.data || [];
          if ((response.data || []).length != 0) {
            state.form.device_type_id = response.data[0].t_device_type_id
            state.table.currentDevice = response.data[0].txt_type_name;
            // 获取省/市
            getProCityOption(0);
          }
        } else {
          state.options.deviceTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      }).finally(() => {
        getList();
      })
    };

    // 获取省/市 选项
    const getProCityOption = (area_code) => {
      getProCityData({
        area_code,
        device_type_id: state.form.device_type_id
      }).then(response => {
        if (response.code == 200) {
          // 0，即为省
          if (area_code == 0) {
            state.options.provinceOption = response.data;
          } else {
            // 否则 为市
            state.options.cityOption = response.data;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取合伙人信息 - 选项
    const getPartners = () => {
      getAgentHasDevice({ int_agent_level: 3 }).then(response => {
        if (response.code == 200 && response.data) {
          state.options.partnerOption = response.data;
        }
      })
    };

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      state.form.device_type_id = state.options.deviceTypeOption[0].t_device_type_id;
      state.form.t_pagent_id = null;
      state.form.paystatus = 0;
      getList();
    };

    return {
      ...toRefs(state),
      getDevices,
      getPartners,
      reset,
      getList,
      handleChange,
      provinceChange,
      numberInput,
      handleSelectionChange,
      confirmInput,
      handleBatchInput,
      batchFormat
    }
  }
}
</script>

<style lang="scss" scoped>
.security-fund-manage-index {
  .sfmi-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;
    margin-top: 4px;

    .el-input {
      margin-right: 6px;
    }
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
